var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.menuExpanded
          ? _c(
              "div",
              {
                staticClass: "fixed top-0 bottom-0 left-0 right-0 z-40 w-full",
                on: { click: _vm.closeMenu }
              },
              [_c("div", { staticClass: "w-full h-full bg-black opacity-75" })]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-fade", mode: "out-in" } }, [
        _vm.menuExpanded
          ? _c(
              "div",
              {
                staticClass:
                  "fixed top-0 bottom-0 right-0 z-50 w-4/5 overflow-y-scroll bg-white",
                attrs: { id: "mobile-menu" }
              },
              [
                _c(
                  "div",
                  { staticClass: "m-2" },
                  [_c("menu-toggle-button")],
                  1
                ),
                _vm._v(" "),
                _c(
                  "nav",
                  {
                    staticClass: "m-nav",
                    attrs: {
                      role: "navigation",
                      "aria-label": "Main Navigation"
                    }
                  },
                  [
                    _c(
                      "ul",
                      _vm._l(_vm.items, function(item) {
                        return _c("nav-item", {
                          key: item.id,
                          attrs: { item: item }
                        })
                      }),
                      1
                    )
                  ]
                )
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }