import { mobileMenu } from "../../components/molecules/mobile-menu/index";
import { bindAccordions } from "../../components/molecules/accordion/index";
import lozad from 'lozad';
import './lib/font-awesome';
import './lib/carousel';

bindAccordions();

const observer = lozad();
observer.observe();

export default {
	mobileMenu,
};
