import Vue from "vue";
import svg4everybody from "svg4everybody";
import polyfill from "./polyfills";
import initModules from "./lib/init-modules";
import modulesEnabled from "./modulesEnabled";
import MicroModal from "micromodal"
// import jQuery from "jquery";

// window.$ = window.jQuery = jQuery;

Vue.config.productionTip = false;

const setJsDocumentClasses = () => {
  const docEl = document.documentElement;

  docEl.classList.remove("js-loading");
	docEl.classList.add("js-loaded");
};

const init = (APP) => {
  APP.DEBUG = typeof APP.DEBUG !== "undefined" ? APP.DEBUG : !process.env.NODE_ENV === "production";
	APP.modules = initModules(APP.modules, modulesEnabled, APP.DEBUG);
};

window.addEventListener("DOMContentLoaded", () => {
  setJsDocumentClasses();
	svg4everybody();
	MicroModal.init();

  polyfill().then(() => {
		init(window.APP);
  });
});

// --------------------------------------------------------------------------------------------------
// Form conditional
// --------------------------------------------------------------------------------------------------
jQuery(document).on( 'nfFormReady', function(  ) {
  jQuery('.quick-quote select.your-help option[value="Hire"]').hide();
  jQuery(".quick-quote select.your-service").change(function(){
      var serviceSelected = jQuery(".quick-quote select.your-service").children("option:selected").val();
      var hireOption = jQuery('.quick-quote select.your-help option[value="Hire"]');
      var helpDropdown = jQuery('.quick-quote .help-field');

      if(serviceSelected !== "Fire Extinguishers") {
        hireOption.hide();
        hireOption.prop('disabled', 'disabled');
      } else {
        hireOption.show();
        hireOption.prop('disabled', false);
      }

      if(serviceSelected == "Fire Risk Assessments" || serviceSelected == "Fire Safety Training") {
        helpDropdown.hide();
      } else {
        helpDropdown.show();
      }
  });

  jQuery('.form-on-page select.your-help option[value="Hire"]').hide();
  jQuery(".form-on-page select.your-service").change(function(){
      var serviceSelected = jQuery(".form-on-page select.your-service").children("option:selected").val();
      var hireOption = jQuery('.form-on-page select.your-help option[value="Hire"]');
      var helpDropdown = jQuery('.form-on-page .help-field');

      if(serviceSelected !== "Fire Extinguishers") {
        hireOption.hide();
        hireOption.prop('disabled', 'disabled');
      } else {
        hireOption.show();
        hireOption.prop('disabled', false);
      }

      if(serviceSelected == "Fire Risk Assessments" || serviceSelected == "Fire Safety Training") {
        helpDropdown.hide();
      } else {
        helpDropdown.show();
      }
  });


});
